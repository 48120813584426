import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import statify from "../../Assets/Projects/statify.png";
import criticinema from "../../Assets/Projects/criticinema.png";
import pdfparser from "../../Assets/Projects/pdf-parser.png";
import profected from "../../Assets/Projects/profected.png";
import mediai from "../../Assets/Projects/mediai.png";
import benchmarking from "../../Assets/Projects/benchmarking.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={pdfparser}
              isBlog={false}
              title="PDF-Parser"
              description="An advanced tool leveraging Python OpenCV, hyperparamter tuned YOLOv8 models, and OpenAI models to automate the extraction and generation of products from product catalogs, streamlining inventory management."
              ghLink="https://github.com/dhruvsharma11/PDF-Parser"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={statify}
              isBlog={false}
              title="Statify"
              description="A data-driven application providing insightful statistics on user music preferences by integrating with Spotify API and offering seamless authentication, enhancing the music listening experience."
              ghLink="https://github.com/dhruvsharma11/statify"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={mediai}
              isBlog={false}
              title="M3DI-AI"
              description="A cutting-edge audio transcription and summarization tool using PyTorch, Whisper OpenAI, and Hugging Face transformer models to accurately transcribe and summarize doctor-patient conversations, enhancing medical documentation."
              ghLink="https://github.com/dhruvsharma11/M3DI_AI"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={criticinema}
              isBlog={false}
              title="CritiCinema"
              description="A comprehensive web application for movie enthusiasts to review films, watch trailers, and perform detailed searches based on an IMDB engine, making film discovery and discussion effortless."
              ghLink="https://github.com/dhruvsharma11/CritiCinema"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={benchmarking}
              isBlog={false}
              title="Breast Cancer Benchmarking"
              description="Benchmarked different Machine Learning Algorithms (Logistic Regression, Decision Trees, K-Nearest Neighbours, Support Vector Machines, and Convulation Neural Networks to find the most optimal method for Breast Cancer Classification)."
              ghLink="https://github.com/dhruvsharma11/Breast-Cancer-Classification"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={profected}
              isBlog={false}
              title="Profected"
              description="A platform designed to bridge the gap between students and industry professionals through an AI-powered (KNN) matchmaking algorithm, fostering mentorship and professional growth."
              ghLink="https://github.com/dhruvsharma11/Profected"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
