import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import Github from "./Github";
import Techstack from "./Techstack";
import AboutCard from "./AboutCard";
import Toolstack from "./Toolstack";
import myImg from "../../Assets/avatar.jpeg";

function About() {
  return (
    <Container fluid className="about-section">
      <Particle />
      <Container>
        <Row style={{ justifyContent: "center", padding: "30px" }}>
          <Col md={12} className="about-img">
            <img src={myImg} alt="about" className="img-fluid" />
          </Col>
          <Col
            md={12}
            style={{ justifyContent: "center", paddingBottom: "20px" }}
          >
            <h1 style={{ fontSize: "2.1rem", paddingTop: "20px" }}>
              About <strong className="purple">ME</strong>
            </h1>
            <AboutCard />
          </Col>
        </Row>
        <h1 className="project-heading">
          Professional <strong className="purple">Skillset</strong>
        </h1>
        <Techstack />
        <h1 className="project-heading">
          <strong className="purple">Tools</strong> I use
        </h1>
        <Toolstack />
        <Github />
      </Container>
    </Container>
  );
}

export default About;
