import React from "react";
import { Col, Row } from "react-bootstrap";
import { CgCPlusPlus } from "react-icons/cg";
import {
  DiJavascript1,
  DiReact,
  DiNodejs,
  DiMongodb,
  DiPython,
  DiGit,
  DiJava,
  DiHtml5,
  DiCss3,
} from "react-icons/di";
import {
  SiTypescript,
  SiPytorch,
  SiPandas,
  SiCsharp,
  SiMysql,
  SiPostgresql,
} from "react-icons/si";

const techstack = [
  { name: "Python", icon: <DiPython />, link: "https://www.python.org/" },
  {
    name: "JavaScript",
    icon: <DiJavascript1 />,
    link: "https://www.javascript.com/",
  },
  {
    name: "TypeScript",
    icon: <SiTypescript />,
    link: "https://www.typescriptlang.org/",
  },
  { name: "Java", icon: <DiJava />, link: "https://www.java.com/" },
  {
    name: "C#",
    icon: <SiCsharp />,
    link: "https://learn.microsoft.com/en-us/dotnet/csharp/",
  },
  {
    name: "HTML5",
    icon: <DiHtml5 />,
    link: "https://developer.mozilla.org/en-US/docs/Web/Guide/HTML/HTML5",
  },
  {
    name: "CSS3",
    icon: <DiCss3 />,
    link: "https://developer.mozilla.org/en-US/docs/Web/CSS/CSS3",
  },
  { name: "React", icon: <DiReact />, link: "https://reactjs.org/" },
  { name: "Node.js", icon: <DiNodejs />, link: "https://nodejs.org/" },
  { name: "PyTorch", icon: <SiPytorch />, link: "https://pytorch.org/" },
  { name: "Pandas", icon: <SiPandas />, link: "https://pandas.pydata.org/" },
  { name: "MongoDB", icon: <DiMongodb />, link: "https://www.mongodb.com/" },
  { name: "MySQL", icon: <SiMysql />, link: "https://www.mysql.com/" },
  {
    name: "PostgreSQL",
    icon: <SiPostgresql />,
    link: "https://www.postgresql.org/",
  },
  { name: "Git", icon: <DiGit />, link: "https://git-scm.com/" },
];

function Techstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      {techstack.map((tech, index) => (
        <Col xs={4} md={2} className="tech-icons" key={index}>
          <a
            href={tech.link}
            target="_blank"
            rel="noopener noreferrer"
            className="tech-link"
          >
            {tech.icon}
            <p>{tech.name}</p>
          </a>
        </Col>
      ))}
    </Row>
  );
}

export default Techstack;
