import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  SiVisualstudiocode,
  SiRailway,
  SiSlack,
  SiVercel,
  SiLinux,
  SiMicrosoftazure,
  SiMysql,
  SiMongodb,
  SiAmazonaws,
  SiGit,
} from "react-icons/si";

const toolstack = [
  { name: "Linux", icon: <SiLinux />, link: "https://www.linux.org/" },
  {
    name: "VS Code",
    icon: <SiVisualstudiocode />,
    link: "https://code.visualstudio.com/",
  },
  { name: "Railway", icon: <SiRailway />, link: "https://railway.app/" },
  { name: "Slack", icon: <SiSlack />, link: "https://slack.com/" },
  { name: "Vercel", icon: <SiVercel />, link: "https://vercel.com/" },
  {
    name: "Azure",
    icon: <SiMicrosoftazure />,
    link: "https://azure.microsoft.com/",
  },
  { name: "MySQL", icon: <SiMysql />, link: "https://www.mysql.com/" },
  { name: "MongoDB", icon: <SiMongodb />, link: "https://www.mongodb.com/" },
  { name: "AWS", icon: <SiAmazonaws />, link: "https://aws.amazon.com/" },
  { name: "Git", icon: <SiGit />, link: "https://git-scm.com/" },
];

function Toolstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      {toolstack.map((tool, index) => (
        <Col xs={4} md={2} className="tech-icons" key={index}>
          <a
            href={tool.link}
            target="_blank"
            rel="noopener noreferrer"
            className="tech-link"
          >
            {tool.icon}
            <p>{tool.name}</p>
          </a>
        </Col>
      ))}
    </Row>
  );
}

export default Toolstack;
